import React from "react";
import { cn } from "./utils";

export function Table({ children, className }: React.HTMLAttributes<HTMLTableElement>) {
  return (
    <table className={cn("w-full border-collapse border border-gray-200", className||"")}>
      {children}
    </table>
  );
}

export function TableHeader({ children, className }: React.HTMLAttributes<HTMLTableSectionElement>) {
  return <thead className={cn("bg-gray-100", className||"")}>{children}</thead>;
}

export function TableBody({ children, className }: React.HTMLAttributes<HTMLTableSectionElement>) {
  return <tbody className={cn("divide-y divide-gray-200", className||"")}>{children}</tbody>;
}

export function TableRow({ children, className }: React.HTMLAttributes<HTMLTableRowElement>) {
  return <tr className={cn("hover:bg-gray-50", className||"")}>{children}</tr>;
}

export function TableHead({ children, className }: React.ThHTMLAttributes<HTMLTableCellElement>) {
  return (
    <th className={cn("px-4 py-2 text-left text-sm font-semibold text-gray-600", className||"")}>
      {children}
    </th>
  );
}

export function TableCell({
    children,
    className,
    ...props // Collect remaining props
  }: React.TdHTMLAttributes<HTMLTableCellElement>) {
    return (
      <td
        className={cn("px-4 py-2 text-sm text-gray-800", className || "")}
        {...props} // Spread additional props onto the <td> element
      >
        {children}
      </td>
    );
  }
//   export function TableCell({
//     children,
//     className,
//     ...props
// }: React.TdHTMLAttributes<HTMLTableCellElement>) {
//     return (
//         <td
//             className={cn("px-4 py-2 text-center text-sm text-gray-700", className || "")}
//             {...props}
//         >
//             {children}
//         </td>
//     );
// }

//   export function TableHeaderCell({
//     children,
//     className,
//     ...props // Collect remaining props
//   }: React.ThHTMLAttributes<HTMLTableCellElement>) {
//     return (
//       <th
//         className={cn("px-4 py-2 text-sm text-center font-bold text-gray-800 important!", className || "")}
//         {...props} // Spread additional props onto the <th> element
//       >
//         {children}
//       </th>
//     );
//   }
export function TableHeaderCell({
    children,
    className,
    ...props
  }: React.ThHTMLAttributes<HTMLTableCellElement>) {
    return (
      <th
        className={cn(
          "px-4 py-2 text-sm font-bold text-gray-800 text-center",
          "flex-1",
          className || ""
        )}
        {...props}
      >
        {children}
      </th>
    );
  }
  