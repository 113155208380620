import React, { useState, useContext } from "react";
import { Card, CardHeader, CardTitle, CardContent } from "../assets/cards";
import { Button } from "../assets/Button";
import { LockOpen } from "lucide-react";
import { IoLockClosed } from "react-icons/io5";
import { Badge } from "../assets/Badge";
import { MyContext } from "../Panel/Panel.js";

const SignupControlPanel = () => {
  const ctx = useContext(MyContext);
  const [isSignupOpen, setIsSignupOpen] = useState(false);

  const handleSignupStatus = (status) => {
    setIsSignupOpen(status === 0);
    ctx.UpdateSignUpStatus(status);
  };

  return (
    <Card className="w-full max-w-xl shadow-md border border-gray-200 rounded-lg">
      <CardHeader>
        <CardTitle className="flex items-center justify-between text-lg font-semibold">
          <span>Signup Page Control</span>
          <Badge
            variant={isSignupOpen ? "success" : "destructive"}
            className="text-sm px-3 py-1.5 rounded-full"
          >
            {isSignupOpen ? "Open" : "Closed"}
          </Badge>
        </CardTitle>
      </CardHeader>
      <CardContent className="p-6 space-y-6">
        <div className="grid grid-cols-2 gap-4">
          <Button
            variant={isSignupOpen ? "outline" : "default"}
            className="flex items-center justify-center gap-2 py-2"
            onClick={() => handleSignupStatus(0)}
          >
            <LockOpen className="h-5 w-5" />
            Open Signup
          </Button>

          <Button
            variant={!isSignupOpen ? "outline" : "default"}
            className="flex items-center justify-center gap-2 py-2"
            onClick={() => handleSignupStatus(1)}
          >
            <IoLockClosed className="h-5 w-5" />
            Close Signup
          </Button>
        </div>

        <div className="mt-4 text-sm text-gray-600">
          {isSignupOpen ? (
            <span className="text-green-600 font-medium">
              Signup page is currently open to new registrations.
            </span>
          ) : (
            <span className="text-red-600 font-medium">
              Signup page is currently closed to new registrations.
            </span>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default SignupControlPanel;
