import React, { useState,useContext } from 'react';
import { MyContext } from "../Panel/Panel.js";

const RecipeAdmin = () => {
    let ctx=useContext(MyContext);

  let imageData=ctx.imageData&&ctx.imageData.length>0?ctx.imageData[0].imageid:'';
  const CLOUDFRONT_URL = "https://dfcmbaekdtnsm.cloudfront.net"; // Replace with your actual CloudFront URL
  const imageUrl = `${CLOUDFRONT_URL}/${imageData}`;




  return (
    <div className="p-4">
      {/* Header */}
      <h1 className="text-2xl font-bold mb-4">Auto generated recipes</h1>

      {/* Fetch Button */}
      <button className="w-full mb-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
      onClick={()=>ctx.getRecipesToProcessHandler()}
      >
        fetch recipes to process
      </button>

      {/* Recipe List */}
      <div className="border rounded-md mb-4">
        <div className="h-[30vh] overflow-y-auto">
          <table className="w-full">
            <thead className="sticky top-0 bg-white">
              <tr>
                <th className="text-left p-2 border-b">recipeid</th>
                <th className="text-left p-2 border-b">recipeName</th>
              </tr>
            </thead>
            <tbody>
              {ctx.recipesToProcess.map((recipe,index) => (
                <tr 
                  key={index}
                  onClick={() =>ctx.setSelectedRecipe(recipe.receptid)}
                  className="hover:bg-gray-100 cursor-pointer"
                >
                  <td className="p-2 border-b">{recipe.receptid}</td>
                  <td className="p-2 border-b">{recipe.benaemn}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {ctx.recipeHeaderData && (
        <>
          {/* Selected Recipe Name Header */}
          <h2 className="text-xl font-bold mb-4">{ctx.recipeHeaderData&&ctx.recipeHeaderData.length>0?ctx.recipeHeaderData[0].benaemn:''}</h2>

          {/* Ingredients List */}
          <div className="border rounded-md mb-4">
            <table className="w-full">
              <thead>
                <tr>
                  <th className="text-left p-2 border-b">receptid</th>
                  <th className="text-left p-2 border-b">ingredid</th>
                  <th className="text-left p-2 border-b">name</th>
                  <th className="text-left p-2 border-b">amount</th>
                </tr>
              </thead>
              <tbody>
                {ctx.recipeData.map((ingredient,index) => (
                  <tr key={index}>
                    <td className="p-2 border-b">{ingredient.receptid}</td>
                    <td className="p-2 border-b">{ingredient.ingredid}</td>
                    <td className="p-2 border-b">{ingredient.benaemn}</td>
                    <td className="p-2 border-b">{ingredient.mangd}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Recipe Instructions Textarea */}
          <textarea 
          value={ctx.recipeHeaderData&&ctx.recipeHeaderData.length>0?ctx.recipeHeaderData[0].btext:''}
            className="w-full mb-4 min-h-[300px] p-2 border rounded-md resize-none"
            placeholder="Recipe instructions..."
            readOnly
          />

          {/* Recipe Image */}
          <div className="w-full h-72 bg-gray-100 rounded-lg mb-4">
            <img
              src={imageUrl}
              alt="Recipe"
              className="w-full h-full object-contain"
            />
          </div>

          {/* Action Buttons */}
          <div className="flex gap-4">
            <button className="flex-1 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            onClick={()=>ctx.insertRecipeHandler()}
            >
              insert into database
            </button>
            <button className="flex-1 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
            onClick={()=>ctx.deleteRecipeHandler()}
            >
              delete recipe
            </button>
            
          </div>
        </>
      )}
    </div>
  );
};

export default RecipeAdmin;