import React, { ButtonHTMLAttributes, FC } from "react";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "default" | "success" | "destructive";
  className?: string;
}

export const Button: FC<ButtonProps> = ({
  children,
  variant = "default",
  disabled = false,
  className = "",
  ...props
}) => {
  const baseStyles =
    "px-4 py-2 rounded-lg text-white font-semibold transition focus:outline-none focus:ring-2";
  const variants: Record<string, string> = {
    default: "bg-gray-500 hover:bg-gray-600 focus:ring-gray-300",
    success: "bg-green-500 hover:bg-green-600 focus:ring-green-300",
    destructive: "bg-red-500 hover:bg-red-600 focus:ring-red-300",
  };

  const disabledStyles = "opacity-50 cursor-not-allowed";

  return (
    <button
      className={`${baseStyles} ${variants[variant]} ${
        disabled ? disabledStyles : ""
      } ${className}`}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
};
