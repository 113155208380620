"use client";

import React, { useState, useMemo } from "react";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { MyContext } from "../Panel/Panel.js";
import "../functions/RequestChart.scss";

export default function RequestsChart() {
  const ctx = React.useContext(MyContext);
  const [chartType, setChartType] = useState("weekly");
  console.log(ctx.backendStatistics);

  // Process weekly data
  const weeklyData = useMemo(() => {
    if (!ctx || !ctx.backendStatistics || !Array.isArray(ctx.backendStatistics[4])) {
      return [];
    }
    return ctx.backendStatistics[5].map((item: any) => ({
      name: item.WeekYear,
      requests: item.TotalRequests,
    }));
  }, [ctx]);

  // Process monthly data
  const monthlyData = useMemo(() => {
    if (!ctx || !ctx.backendStatistics || !Array.isArray(ctx.backendStatistics[3])) {
      return [];
    }
    return ctx.backendStatistics[4].map((item: any) => ({
      name: item.MonthName,
      requests: item.TotalRequests,
    }));
  }, [ctx]);

  // Map chart data based on the selected type
  const chartData = chartType === "weekly" ? weeklyData : monthlyData;

  return (
    <div className="requests-chart">
      <div className="select-container">
        <label htmlFor="chart-type-select" className="select-label">
          Select Chart Type
        </label>
        <select
          id="chart-type-select"
          value={chartType}
          onChange={(e) => setChartType(e.target.value)}
          className="chart-select"
        >
          <option value="weekly">Weekly Requests</option>
          <option value="monthly">Monthly Requests</option>
        </select>
      </div>
      <ResponsiveContainer width="100%" height={350}>
        <BarChart data={chartData}>
          <XAxis dataKey="name" />
          <YAxis />
          <Bar dataKey="requests" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

