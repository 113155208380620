
'use client'

import { useState } from 'react'
import { CreditCard } from 'lucide-react'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '../assets/cards'
import { Input } from '../assets/Input'
import { Button } from '../assets/Button'
import { Label } from '../assets/Label'
import { Alert, AlertDescription } from '../assets/Alert'
import { MyContext } from '../Panel/Panel.js';
import { useContext } from 'react';

export default function CreditCardSettings() {
    const ctx = useContext(MyContext);




  return (
    <div className="container mx-auto py-10 px-4 max-w-2xl">
      <Card className="w-full">
        <CardHeader className="space-y-1">
          <div className="flex items-center space-x-2">
            <CreditCard className="h-5 w-5 text-primary" />
            <CardTitle className="text-2xl">Live Credit Card Settings</CardTitle>
          </div>
          <CardDescription>
            Here we update the credit card settings for live.bakesoft.se
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-6">
          <div className="space-y-2">
            <p className="text-sm text-muted-foreground">
              Credit card settings is the base of the system, USD is the base currency.
            </p>
          </div>

          <form className="space-y-4" onSubmit={(e) => {
    e.preventDefault(); // Prevent the form from submitting
    console.log("Form submission prevented!");
  }}
>
            <div className="space-y-2">
              <Label htmlFor="fee">
                Credit Card Fee that we charge when users are getting payed to cover the cost for transfer
              </Label>
              <div className="flex items-center space-x-2">
                <Input
                  id="fee"
                  type="number"
                  step="0.01"
                  min="0"
                  value={ctx.creditCardFee}
                  onChange={(e)=>ctx.SetCreditCardFeeHandler(e.target.value)}
                  className="max-w-[200px]"
                />
                <span className="text-sm text-muted-foreground">USD</span>
              </div>
            </div>

            <Button type="submit" className="w-full"
               onClick={()=>ctx.UpdateFeeHandler()}
            >Set Credit Card Settings
            </Button>
          </form>

          <Alert className="mt-6">
            <AlertDescription>
              Now valid credit card fee in live.bakesoft.se:{' '}
              <span className="font-semibold"> {ctx.creditCardFee} USD</span>
            </AlertDescription>
          </Alert>
        </CardContent>
      </Card>
    </div>
  )
}

