import React from "react";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../assets/cards";
import { MyContext } from '../Panel/Panel.js'; 


interface StatsCardProps {
  title: string;
  value: number;
  description: string;
}


function StatsCard({ title, value, description }: StatsCardProps) {

  return (
    <Card>
      <CardHeader>
        <CardTitle>{title&&title}</CardTitle>
        <CardDescription>{description&&description}</CardDescription>
      </CardHeader>
      <CardContent>
        <p className="text-4xl font-bold">{value.toLocaleString()}</p>
      </CardContent>
    </Card>
  );
}

export default function StatsCards() {
    
  // In a real application, you would fetch this data from your API
  let ctx=React.useContext(MyContext)
  // Validate the context and its structure
  if (!ctx || !ctx.backendStatistics || !Array.isArray(ctx.backendStatistics)) {
    console.error("Context data is unavailable or invalid");
    return <div>Loading...</div>;
  }

  // Safely access nested values with detailed checks
  const totalRows =
    Array.isArray(ctx.backendStatistics[0]) && ctx.backendStatistics[0][0]?.TotalRows !== undefined
      ? ctx.backendStatistics[0][0].TotalRows
      : 0;

  const totalRowsPerWeek =
    Array.isArray(ctx.backendStatistics[1]) && ctx.backendStatistics[1][0]?.TotalRowsPerWeek !== undefined
      ? ctx.backendStatistics[1][0].TotalRowsPerWeek
      : 0;

  const totalRowsPerMonth =
    Array.isArray(ctx.backendStatistics[2]) && ctx.backendStatistics[2][0]?.TotalRowsPerMonth !== undefined
      ? ctx.backendStatistics[2][0].TotalRowsPerMonth
      : 0;

  const stats = [
    { title: "Total Requests", value: totalRows, description: "All-time visitor count" },
    { title: "This Week", value: totalRowsPerWeek&&totalRowsPerWeek>0?totalRowsPerWeek:'', description: "Visitor count for the current week" },
    { title: "This Month", value: totalRowsPerMonth&&totalRowsPerMonth>0?totalRowsPerMonth:'', description: "Visitor count for the current month" },
  ];

  return (
    <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
      {stats.map((stat, index) => (
        <StatsCard key={index} {...stat} />
      ))}
    </div>
  );
}
