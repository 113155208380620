import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from "../assets/cards";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../assets/table";
import { Input } from "../assets/Input";
import { Search } from "lucide-react";
import { MyContext } from '../Panel/Panel.js';
import { useContext } from 'react';

const ExchangeRatesNew = () => {
    const ctx = useContext(MyContext);
  const [searchTerm, setSearchTerm] = React.useState("");

//   const rates = [
//     { name: "Sweden", rate: 0.090511, currencyName: "SEK (Swedish Krona)", currencyShort: "SEK" },
//     { name: "USA", rate: 1.000000, currencyName: "USD (US Dollar)", currencyShort: "USD" },
//     { name: "Japan", rate: 0.006347, currencyName: "YEN (Japanese Yen)", currencyShort: "JPY" },
//     { name: "Great Britain", rate: 1.250469, currencyName: "POUND (British Pound)", currencyShort: "GBP" },
//     { name: "Canada", rate: 0.697253, currencyName: "CAD (Canadian Dollar)", currencyShort: "CAD" },
//     { name: "Australia", rate: 0.624259, currencyName: "AUD (Australian Dollar)", currencyShort: "AUD" },
//     { name: "Brazil", rate: 0.163613, currencyName: "BRL (Brazilian Real)", currencyShort: "BRL" },
//     { name: "Korea", rate: 0.000684, currencyName: "KRW (South Korean Won)", currencyShort: "KRW" },
//     { name: "Singapore", rate: 0.733514, currencyName: "SGD (Singapore Dollar)", currencyShort: "SGD" },
//     { name: "Mexico", rate: 0.049181, currencyName: "MXN (Mexican Peso)", currencyShort: "MXN" }
//   ];

const filteredRates = Array.isArray(ctx.latestRates)
  ? ctx.latestRates.filter(
      (rate) =>
        rate.ccName?.toLowerCase().includes(searchTerm?.toLowerCase() || "") ||
        rate.currencyName?.toLowerCase().includes(searchTerm?.toLowerCase() || "") ||
        rate.currencyShort?.toLowerCase().includes(searchTerm?.toLowerCase() || "")
    )
  : [];

  return (
    <Card className="w-full max-w-6xl">
      <CardHeader>
        <CardTitle className="text-2xl font-bold mb-4">Exchange Rates</CardTitle>
        <div className="relative mb-4">
          <Search className="absolute left-2 top-3 h-4 w-4 text-gray-500" />
          <Input
            placeholder="Search currency..."
            className="pl-8"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <button
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          onClick={()=>ctx.GetXchangeRatesHandler()}
        >
          Fetch Exchange Rates
        </button>
      </CardHeader>
      <CardContent>
        <div className="overflow-x-auto">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="w-[400px]">Country</TableHead>
                <TableHead className="text-right">Rate</TableHead>
                <TableHead>Currency Name</TableHead>
                <TableHead className="text-center">Code</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
            {filteredRates.map((rate,index)=>
                <TableRow key={index}>
                  <TableCell className="font-medium">{rate.ccName}</TableCell>
                  <TableCell className="text-right">{rate.xchangeRate}</TableCell>
                  <TableCell>{rate.currencyName}</TableCell>
                  <TableCell className="text-center">
                    <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                      {rate.currencyShort}
                    </span>
                  </TableCell>
                </TableRow>
              )}


            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
};

export default ExchangeRatesNew;
