"use client";

import React from "react";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../assets/table.tsx";
import { MyContext } from "../Panel/Panel.js";
import SupportTicketDetail from "../functions/SupportTicketDetail.js";
import formatDateTime from './formatDateTime.js';

export default function SupportList() {
  const ctx = React.useContext(MyContext);
  const SupportTicketHandler=(id)=>{
  ctx.SupportTicketHandler(id);
  }
  return (
    <div className="p-6 bg-gray-100 rounded-lg">
      <h1 className="text-2xl font-bold text-center mb-6 text-green-700">Support Panel</h1>
      <div className="overflow-x-auto">
        <Table className="min-w-full text-sm border-collapse border border-gray-200">
          <TableHeader>
            <TableRow className="bg-gray-200">
              <TableHead className="text-left font-semibold border-b border-gray-300">S-ticket</TableHead>
              <TableHead className="text-left font-semibold border-b border-gray-300">Name</TableHead>
              <TableHead className="text-left font-semibold border-b border-gray-300">Date</TableHead>
              <TableHead className="text-left font-semibold border-b border-gray-300">Status</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {ctx.supportList.map((item,key) => (

              <TableRow key={key} className="hover:bg-gray-50">
                <TableCell className="border-b border-gray-200">{item.supportid}</TableCell>
                <TableCell className="border-b border-gray-200 cursor-pointer"
                onClick={()=>SupportTicketHandler(item.supportid)}
                >{`${item.givenName} ${item.familyName}`}</TableCell>
                <TableCell className="border-b border-gray-200">{formatDateTime(item.createDate)}</TableCell>
                <TableCell
                  className={`border-b border-gray-200 font-medium ${
                    item.statusName === "Klar"
                      ? "text-green-600"
                      : item.statusName === "Pågående"
                      ? "text-yellow-600"
                      : "text-red-600"
                  }`}
                >
                  {item.statusName}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
         {ctx.getShowSupportTicket===true?<SupportTicketDetail supportTicketId={ctx.getSupportTicket}/>:null}
    </div>
    
  );
}

