import React, { useContext } from 'react';
import { MyContext } from "../Panel/Panel.js";

const DeleteLogoImages = () => {
    let ctx = useContext(MyContext);

    return (
        <div className="p-8 bg-gray-50 min-h-screen">
            <div className="max-w-4xl mx-auto">
                {/* Description text */}
                <div className="text-gray-600 mb-6 space-y-2">
                    <p>This page delete logo images, socialmedia logo images only.</p>
                    <p>Images that are deleted are those images not requested by socialmedia platforms for 60 days.</p>
                </div>

                {/* Header */}
                <h1 className="text-3xl font-bold text-gray-900 mb-8">
                    SocialMedia Logo Images
                </h1>
                
                {/* Main content container */}
                <div className="space-y-6">
                    {/* Fetch button */}
                    <div>
                        <button 
                            className="w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-3 px-6 rounded-lg transition-colors duration-200"
                            onClick={() => ctx.GetImagesReadyHandler()}
                        >
                            Fetch Images to Delete
                        </button>
                    </div>

                    {/* Info areas */}
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                        {/* Images ready to delete */}
                        <div className="bg-white p-6 rounded-lg border border-gray-200 shadow-sm">
                            <div className="text-sm text-gray-500 mb-1">Images Ready to Delete</div>
                            <div className="text-3xl font-bold text-gray-900">{ctx.inactiveImagesToDelete||0}</div>
                        </div>

                        {/* Last deletion date */}
                        <div className="bg-white p-6 rounded-lg border border-gray-200 shadow-sm">
                            <div className="text-sm text-gray-500 mb-1">Last Deletion Date</div>
                            <div className="text-3xl font-bold text-gray-900">{String(ctx.lastDeleteDate).substring(0,10)}</div>
                        </div>
                    </div>
                    
                    {/* Action buttons container */}
                    <div className="flex flex-col sm:flex-row gap-4">
                        <button 
                            className="flex-1 bg-red-600 hover:bg-red-700 text-white font-medium py-3 px-6 rounded-lg transition-colors duration-200"
                            onClick={() => ctx.deleteImagesHandler()}
                        >
                            Delete Images
                        </button>
                        
                        <button 
                            className="flex-1 bg-yellow-600 hover:bg-yellow-700 text-white font-medium py-3 px-6 rounded-lg transition-colors duration-200"
                            onClick={() => console.log('Mark as deleted')}
                        >
                            Im waiting to be used
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeleteLogoImages;