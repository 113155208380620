import React from "react"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../assets/table"
import { MyContext } from '../Panel/Panel.js'; 
type RowData = {
  id: number;
  grouped_timestamp: string;
  benaemn: number;
  primaryLanguage: number;
  request_type: string;
};

const mockData = [
  { id: 1, date: "2023-07-01", requests: 1234, uniqueUsers: 987, topReferrer: "facebook.com" },
  { id: 2, date: "2023-07-02", requests: 2345, uniqueUsers: 1876, topReferrer: "twitter.com" },
  { id: 3, date: "2023-07-03", requests: 3456, uniqueUsers: 2765, topReferrer: "instagram.com" },
  { id: 4, date: "2023-07-04", requests: 4567, uniqueUsers: 3654, topReferrer: "linkedin.com" },
  { id: 5, date: "2023-07-05", requests: 5678, uniqueUsers: 4543, topReferrer: "tiktok.com" },
]

export default function DailyRequestsList() {
    let ctx=React.useContext(MyContext)
  //  console.log(ctx.backendStatistics[3][0])
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Date</TableHead>
          <TableHead>Total Requests</TableHead>
          <TableHead>Unique Users</TableHead>
          <TableHead>Top Referrer</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {ctx.backendStatistics[3]&&ctx.backendStatistics[3].map((row: RowData,index:any) => (
          <TableRow key={index}>
            <TableCell>{row.grouped_timestamp}</TableCell>
            <TableCell>{row.benaemn}</TableCell>
            <TableCell>{row.primaryLanguage}</TableCell>
            <TableCell>{row.request_type}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
