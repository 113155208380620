
import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "../assets/cards";
import { Button } from "../assets/Button";
import { Input } from "../assets/Input";
import { Badge } from "../assets/Badge";
import { useState } from 'react';
import { MyContext } from '../Panel/Panel.js';
import { useContext } from 'react';
import formatDateTime from './formatDateTime.js';

    const PayoutPage = () => {
        const ctx = useContext(MyContext);

        let sekbalance=ctx.payPalSEKBalance;
let usdbalance=ctx.payPalUSDBalance;
let japbalance=ctx.payPalJAPBalance
        return (
          <div className="min-h-screen bg-white p-8">
            <div className="max-w-6xl mx-auto space-y-8">
              {/* Account Balance Section */}
              <Card className="border-none shadow-lg">
                <CardHeader className="flex flex-row items-center justify-between">
                  <CardTitle className="text-2xl font-semibold">Account Balance</CardTitle>
                  <Button variant="outline" className="bg-black text-white hover:bg-gray-800"
                   onClick={()=>ctx.GetAccountBalanceHandler()}
                  >
                    Get Account Balance
                  </Button>
                </CardHeader>
                <CardContent className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <div className="space-y-2">
                    <label className="text-sm font-medium text-gray-500">SEK Balance</label>
                    <Input className="bg-gray-50" readOnly
                    value={sekbalance}
                    />
                  </div>
                  <div className="space-y-2">
                    <label className="text-sm font-medium text-gray-500">USD Balance</label>
                    <Input className="bg-gray-50" readOnly 
                    value={usdbalance}
                    />
                  </div>
                  <div className="space-y-2">
                    <label className="text-sm font-medium text-gray-500">JPY Balance</label>
                    <Input className="bg-gray-50" readOnly 
                    value={japbalance}
                    />
                  </div>
                </CardContent>
              </Card>
      
              {/* Payouts Table */}
              <Card className="border-none shadow-lg">
                <CardHeader>
                  <CardTitle className="text-2xl font-semibold">Payout History</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="overflow-x-auto">
                    <table className="w-full border-collapse">
                      <thead>
                        <tr className="bg-gray-50 border-b">
                          <th className="px-4 py-3 text-left text-sm font-medium text-gray-700">ID</th>
                          <th className="px-4 py-3 text-left text-sm font-medium text-gray-700">User</th>
                          <th className="px-4 py-3 text-left text-sm font-medium text-gray-700">Amount</th>
                          <th className="px-4 py-3 text-left text-sm font-medium text-gray-700">Status</th>
                          <th className="px-4 py-3 text-left text-sm font-medium text-gray-700">Date</th>
                          <th className="px-4 py-3 text-left text-sm font-medium text-gray-700">Payout Date</th>
                          <th className="px-4 py-3 text-left text-sm font-medium text-gray-700">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ctx.payOutListInfo.map((payout, index) => (
                          <tr 
                            key={index} 
                            className="border-b hover:bg-gray-50 transition-colors"
                          >
                            <td className="px-4 py-3 font-mono text-sm">{payout.userid}</td>
                            <td className="px-4 py-3">{payout.givenName+' ' +payout.familyName}</td>
                            <td className="px-4 py-3">${payout.amount}</td>
                            <td className="px-4 py-3">
                              {payout.Istatus === 0 ? (
                                <Badge variant="outline" className="bg-yellow-50 text-yellow-700 border-yellow-200">
                                  Pending
                                </Badge>
                              ) : (
                                <Badge variant="outline" className="bg-green-50 text-green-700 border-green-200">
                                  Paid
                                </Badge>
                              )}
                            </td>
                            <td className="px-4 py-3 font-mono text-sm">{formatDateTime(payout.createDate)}</td>
                            <td className="px-4 py-3 font-mono text-sm">
                            {payout.payDate && new Date(payout.payDate).getFullYear() >= 2023
                              ? formatDateTime(payout.payDate) 
                            : ''}


                            </td>
                            <td className="px-4 py-3">
                              {payout.Istatus === 0 ? (
                                <Button variant="outline" className="bg-black text-white hover:bg-gray-800"
                                onClick={()=>ctx.PayoutHandler(payout.userid,payout.bsliveuserPayoutId)}
                                >
                                  Process
                                </Button>
                              ) : (
                                <Button variant="outline" disabled className="text-gray-400">
                                  Completed
                                </Button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        );

};

export default PayoutPage;