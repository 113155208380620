import React from "react";
import { Button } from "../assets/Button.tsx";
import { Badge } from "../assets/Badge.tsx";
import { useContext } from "react";
import { MyContext } from "../Panel/Panel.js";

export default function InvoicingPage() {
    const ctx = useContext(MyContext);
  const isInvoicing = false; // Example state for invoicing status

  return (
    <div className="p-8 bg-gray-50 min-h-screen flex flex-col items-center">
      <h1 className="text-3xl font-bold text-green-700 mb-6">Invoices</h1>

      <div className="flex flex-col items-center space-y-4">
        {/* Start Invoicing Button */}
        <Button
          className="w-64"
          variant="success"
        
          onClick={()=>ctx.setInvoiceStatusHandler(1)}
        >
          Start Invoicing
        </Button>

        {/* Stop Invoicing Button */}
        <Button
          className="w-64"
          variant="destructive"
          onClick={()=>ctx.setInvoiceStatusHandler(0)}
        >
          Stop Invoicing
        </Button>

        {/* Status Badge */}
        <Badge variant="outline" className="text-lg">
          {ctx.getInvoiceStatus===true ? "Currently Invoicing" : "We are not invoicing"}
        </Badge>
      </div>
    </div>
  );
}
