import React, { FC } from "react";

interface BadgeProps {
  variant?: "default" | "outline" | "success" | "destructive";
  className?: string;
  children: React.ReactNode;
}

export const Badge: FC<BadgeProps> = ({
  children,
  variant = "default",
  className = "",
  ...props
}) => {
  const baseStyles = "inline-block px-3 py-1 rounded-full text-sm font-medium";
  const variants: Record<string, string> = {
    default: "bg-gray-200 text-gray-800",
    outline: "border border-gray-400 text-gray-800",
    success: "bg-green-100 text-green-700",
    destructive: "bg-red-100 text-red-700",
  };

  return (
    <span
      className={`${baseStyles} ${variants[variant]} ${className}`}
      {...props}
    >
      {children}
    </span>
  );
};
