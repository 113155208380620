import React from "react"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../assets/tabs"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../assets/cards"
import StatsCards from "../functions/DailyRequestStat"
import DailyRequestsList from "./DailyRequestList"
import RequestsChart from "../functions/DailyRequestChart"

export default function AdminStatistics() {
  return (
    <div className="container mx-auto py-10">
      <h1 className="text-3xl font-bold mb-6">&nbsp;&nbsp;Admin Statistics</h1>
      <StatsCards />
      <Tabs defaultValue="list" className="mt-6">
        <TabsList>
          <TabsTrigger value="list">Daily Requests</TabsTrigger>
          <TabsTrigger value="chart">Charts</TabsTrigger>
        </TabsList>
        <TabsContent value="list">
          <Card>
            <CardHeader>
              <CardTitle>Daily Requests</CardTitle>
              <CardDescription>List of requests by day</CardDescription>
            </CardHeader>
            <CardContent>
              <DailyRequestsList />
            </CardContent>
          </Card>
        </TabsContent>
        <TabsContent value="chart">
          <Card>
            <CardHeader>
              <CardTitle>Request Charts</CardTitle>
              <CardDescription>Visualizations of request data</CardDescription>
            </CardHeader>
            <CardContent>
              <RequestsChart />
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  )
}