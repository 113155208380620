
import React from "react";

import { Card, CardHeader, CardContent } from "../assets/cards.tsx";
import { MyContext } from '../Panel/Panel.js'; 
import { useContext } from 'react';
function Dashboard1() {
    const ctx = useContext(MyContext);
  const stats = [
    { title: "Total Users", value:ctx.totalUsers, icon: "👤" },
    { title: "Active Services", value:ctx.activeServices, icon: "🛠️" },
    { title: "Total Ingredients", value:ctx.totalIngredients, icon: "🌿" },
    { title: "Total Recipes", value:ctx.totalRecipes&&ctx.totalRecipes>0?ctx.totalRecipes:0, icon: "📜" },
    { title: "Total Products", value:ctx.totalProducts&&ctx.totalProducts>0?ctx.totalProducts:0, icon: "📦" },
    { title: "Total Productions", value:ctx.totalProductions && ctx.totalProductions>0?ctx.totalProductions:0, icon: "🏭" },
  ];

  return (
    <div className="p-8 bg-gray-50 min-h-screen">
      <h1 className="text-3xl font-bold text-gray-800 mb-6">Dashboard</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6">
        {stats.map((stat, index) => (
          <Card key={index} className="shadow-md hover:shadow-lg transition-shadow duration-300">
            <CardHeader>
              <div className="text-2xl">{stat.icon}</div>
              <h2 className="text-xl font-semibold text-gray-700">{stat.title}</h2>
            </CardHeader>
            <CardContent>
              <p className="text-3xl font-bold text-gray-900">{stat.value}</p>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
}
export default Dashboard1;