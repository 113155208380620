import React from 'react';
import {MyContext} from '../Panel/Panel';
import {useContext} from 'react';
function SupportTicketDetail(props){
    let {supportTicketId}=props;
let ctx = useContext(MyContext);
let supportList=ctx.supportList.filter((support)=>support.supportid===supportTicketId);

let chosenCategory=ctx.getSupportCategories.filter((category)=>category.kategoriid===supportList[0].kategoriid)
let chosenBenaemn=chosenCategory&&chosenCategory.length>0?chosenCategory[0].benaemn:'';
let chosenCategoryId=chosenCategory&&chosenCategory.length>0?chosenCategory[0].kategoriid:'';
let supportStatus=ctx.getSupportStatus.filter((status)=>status.statusId===supportList[0].Istatus);
let supportStatusBenaemn=supportStatus[0].benaemn;
let supportStatusId=supportStatus[0].statusId;




    return(
        <div className="panelSupportTicketDetailWrapper">
            <div className="panelHeaderTitle">Support Ticket</div>
            <div className="panelSupportTicketDetailInfo">
                <div className="panelSupportTicketDetailTitle">Ticket id:{supportList[0].supportid}</div>
                <div className="panelSupportTicketDetailTitle">Name:{supportList[0].givenName + ' ' + supportList[0].familyName}</div>
                <textarea className="panelSupportTicketDetailTextArea" value={supportList&&supportList.length>0?supportList[0].benaemn:''} 
                onChange={(e)=>ctx.SupportTextAreaHandler(e.target.value,
                    supportList&&supportList.length>0?supportList[0].supportid:'')}
                ></textarea>
                <div className="panelSupportTicketSelectTop">{ctx.supportCategoryText}
                <select className="panelSupportTicketDetailSelect" onChange={(e)=>ctx.UpdateSupportCategoryHandler(e.target.value,supportTicketId)}>
                <option value={chosenCategoryId}>{chosenBenaemn}</option>
                {ctx.getSupportCategories.map((category,index)=>
                    <option key={index} value={category.kategoriid}>{category.benaemn}</option>
                )}
                    </select>
                    {ctx.supportStatusText}
                    <select className="panelSupportTicketDetailSelect"
                    onChange={(e)=>ctx.UpdateSupportStatusHandler(e.target.value,supportTicketId)}
                    >
                    <option value={supportStatusId}>{supportStatusBenaemn}</option>
                        {ctx.getSupportStatus.map((status,index)=>
                            <option key={index} value={status.statusId}>{status.benaemn}</option>
                        )}


                    </select>
                </div>
                <div className="panelSupportTicketDetailButtonWrapper">
                <button className="panelSupportTicketDetailButton"
                onClick={()=>ctx.UpdateSupportTicketHandler(supportList[0].supportid)}
                >Update</button>
            
             </div>
           
            </div>
        </div>
    )
}
export default SupportTicketDetail;