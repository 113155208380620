

const AppError = {
    se: {
        400000: "Kan inte hämta data",
        400001: "Fel användarnamn eller lösenord",
        450000: "Kan inte hämta fakturerings status",
        450001:"Kan inte uppdatera fakturerings status",
        450002:"Kan inte hämta person data ifrån backend",
        450003:"Kan inte hämta pdf faktura på kund",
        450004:"Admin token är inte giltig",
        450005:"Kan inte stänga av användare från adminpanel",
        450006:"Kan inte hämta sign up status från backend",
        450007:"Kan inte hämta sign up status från backend",
        450008:"Kan inte hämta support tickets från backend",
        450009:"kan inte uppdatera support tickets från backend",
        450010:"Kan inte uppdatera exhangeRates på backend",
        450011:"Kan inte hämta scolllimitis från backend",
        450012:"Kan inte uppdatera scolllimitis på backend",
        450013:"Kan inte uppdatera system kredit pris",
        450014:"Kan inte uppdatera system kredit kort pris",
        450015:"Kan inte hämta företagets paypal balans",
        450016:"Kan inte utfärda en utbetalning till en live skapare",
        450017:"Kan inte hämta backend statistik",
        450018:"Kan inte hämta backend statistik",
        4500019:"Kan inte hämta recept data från backend",
        450020:"Kan inte hämta recept att processa på backend",
        450021:"Kan inte skapa main data",
        errMenu:{
        felkod: "felkod:",
        btnMessage: "Stäng",
        
    }
    },

    en: {
        400000: "Cannot retrieve data",
        400001: "Wrong username or password",
        450000: "Cannot retrieve invoicing status",
        450001:"Cannot update invoicing status",
        450002:"Cannot retrieve person data from backend",
        450003:"Cannot retrieve pdf invoice on customer",
        450004:"Admin token is not valid",
        450005:"Cannot turn of user from adminpanel",
        450006:"Cannot fetch sign up status from backend",
        450007:"Cannot fetch sign up status from backend",
        450008:"Cannot fetch support tickets from backend",
        450009:"cannot update support tickets from backend",
        450010:"Cannot update exhangeRates on backend",
        450011:"Cannot fetch scolllimitis from backend",
        450012:"Cannot update crolllimitis on backend",
        450013:"Cannot update system credit price",
        450014:"Cannot update system credit card price",
        450015:"Cannot get company paypal balance",
        450016:"Cannot issue a payout to a live creator",
        450017:"Cannot fetch backend statistics",
        450018:"Cannot fetch backend statistics",
        450019:"Cannot fetch recipe data from backend",
        450020:"Cannot fetch recipes to process on backend",
        450021:"Cannot create main data",
        errMenu: {
            felkod: "error code:",
            btnMessage: "Close",
        }
    },

    ja: {
        400000: "データを取得できません",
        400001: "間違ったユーザー名またはパスワード",
        450000: "請求ステータスを取得できません",
        450001:"請求ステータスを更新できません",
        450002:"バックエンドからの個人データを取得できません",
        450003:"顧客のPDF請求書を取得できません",
        450004:"管理者トークンが無効です",
        450005:"管理パネルからユーザーをオフにできません",
        450006:"バックエンドからのサインアップステータスを取得できません",
        450007:"バックエンドからのサインアップステータスを取得できません",
        450008:"バックエンドからのサポートチケットを取得できません",
        450009:"バックエンドからのサポートチケットを更新できません",
        450010:"バックエンドで為替レートを更新できません",
        450011:"バックエンドからscrolllimitisを取得できません",
        450012:"バックエンドでscrolllimitisを更新できません",
        450013:"システムクレジット価格を更新できません",
        450014:"システムクレジットカード価格を更新できません",
        450015:"会社のpaypal残高を取得できません",
        450016:"ライブクリエイターに支払いを発行できません",
        450017:"バックエンドの統計を取得できません",
        450018:"バックエンドの統計を取得できません",
        450019:"バックエンドからレシピデータを取得できません",
        450020:"バックエンドで処理するレシピを取得できません",
        450021:"メインデータを作成できません",
        errMenu: {
            felkod: "エラーコード:",
            btnMessage: "閉じる",
        }
    }
}



export default AppError;